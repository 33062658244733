<template>
  <div>
    <div v-if="!isInitiallyLoading">
      <template v-if="!isReisenBusy">
        <ReisenMetadataTable @reiseUpdated="onReiseUpdated" :reise="reise"></ReisenMetadataTable>
      </template>
    </div>
    <div v-else class="card card-custom p-0">
      <div class="card-header">
        <div class="card-title">
          <b-skeleton height="24px" width="600px"></b-skeleton>
        </div>
      </div>
      <div class="card-body p-0">
        <b-skeleton-table :rows="6" :columns="7"></b-skeleton-table>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { mapState } from 'vuex';
import ReisenMetadataTable from '@/components/produkte/reisen/reisen-metadata.vue';

export default {
  name: 'reisen-edit-view',
  props: {
    title: String,
    reise: Object,
  },
  components: {
    ReisenMetadataTable,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isReisenBusy: state => state.reisen.isBusy,
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
      editingMetadata: state => state.reisenMetadata.editingMetadata,
      isAddingMetadata: state => state.reisenMetadata.isAddingMetadata,
    }),
  },
  methods: {
    onReiseUpdated() {
      this.$emit('reiseUpdated');
    },
  },
};
</script>
